import React from 'react'
import {Link} from 'react-router-dom'
import Header from "../../components/Header"
import serviceImg from '../../images/serviceImg.jpg'
import VisitCare from '../../images/VisitCare.jpg'
import LiveIn from '../../images/LiveIn.jpg'
import Meta from '../../components/Meta'

import './services.css'


const Services = () => {
  return (
    <>
    <Meta
      title="Care & Support Services | Odins Medical"
      description="Explore Odins Medical's professional Care & Support Services, including Visiting and Live-in care. Our trained caregivers provide personalized and compassionate support 24/7."
      keywords="care services, home care, elderly care, live-in care, visiting care, medical care, support services, Odins Medical"
    />

    <Header className="servicesImg" title="Care & Support Services" image={serviceImg}
      children= {
        <div className="bttn__card">
          <button className="lnk">
          <Link to="/contact" className='lnk'> Contact us </Link>
          </button>
        </div>}>
    </Header>

        <section className="services">
          <div className="container services-cointainer">
            <div className="services_section-image">
                <img src={VisitCare} alt="Professional Visiting Care Services - Odins Medical" />
            </div>
            <div className="services_section-content">
                <h3>Visiting care</h3>
                <p>
                  Odins Medical Visiting Care Services are tailored to your needs. Our care professionals are available 24 hours a day and 7 days a week, to provide you care and support ranging from simple tasks to complex specialist intervention, at the frequency that suits your need, which could range from half an hour a day/week to several hours' multiple times a day. We will develop bespoke care and support plan with you and your loved ones to determine what your needs are and how often you need our visit. 
                </p>
                <p> 
                  Our Visiting care and support services covers Personal care, Domestic assistance, Nutrition & Hydration, Medication assistance, Overnight care, Respite care, and Companionship & Outings. 
                </p>
                <p>
                  When you make the difficult decision that you need help at home, you need people who know what they are doing. At Odins Medical, quality and professionalism are integral to our culture and processes. Our training and recruitment approach ensures we onboard only highly trained, very professional, and compassionate care professionals.   
                </p>

                <div className="bttn__card">
                    <button>
                    <Link to="/contact" className='lnk'> Contact us </Link>
                    </button>
                </div>
            </div>
          </div>

        </section>

        <section className="services-liveIn">
          <div className="container cservices-cointainer">
            <div className="servicesLiveIn_section-image">
                <img src={LiveIn} alt="24/7 Live-in Care Support by Odins Medical" />
            </div>
            <div className="servicesLiveIn_section-content">
                <h3>Live-in care</h3>
                <p>
                  Sometimes the care and support that you or your loved one needs may require a care professional to be with you or your loved one 24 hours a day. Odins Medical Live-in Care and Support Service provides you or your loved one with dedicated care professional who is available in your home 24 hours a day 7 days a week for as long as you need. Our Live-in care and support services covers Personal care, Domestic assistance, Nutrition & Hydration, Medication assistance, Respite care, and Companionship & Outings. 
                </p>
                <p>
                  When you make the difficult decision that you need help at home, you need people who know what they are doing. At Odins Medical, quality and professionalism are integral to our culture and processes. Our training and recruitment approach ensures we onboard only highly trained, very professional, and compassionate care professionals   
                </p>

                <div className="bttn__card">
                    <button>
                    <Link to="/contact" className='lnk'> Contact us </Link>
                    </button>
                </div>
            </div>
          </div>

        </section>
    </>
  )
}

export default Services