import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser'
import Meta from '../../components/Meta'
import StructuredData from '../../components/structuredData/StructuredData'

import Header from '../../components/Header'
import HeaderImage from '../../images/nod8.jpg'
import {FaWhatsapp} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {RiTwitterXFill} from 'react-icons/ri'

import './contact.css'

//npm i emailjs/browser



const Contact = () => {
    const contactStructuredData = {
      "@type": "ContactPage",
      "mainEntity": {
        "@type": "Organization",
        "name": "Odins Medical",
        "url": "https://yourwebsite.com/contact",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "0191 323 0057",
          "contactType": "customer service",
          "email": "admin@odinsmedical.com"
        },
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Collingwood Buildings, 38 Collingwood Street",
          "addressLocality": "Newcastle Upon Tyne",
          "postalCode": "NE1 1JF",
          "addressCountry": "GB"
        }
      }
    };

  const form = useRef();

  const [isFormValid, setIsFormValid] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Get the form data
    const formData = new FormData(form.current);
    const userName = formData.get('user_name');
    const userEmail = formData.get('user_email');
    const message = formData.get('message');
  
    // Perform validation
    if (!userName || !userEmail || !message) {
      alert('Please fill in all required fields.');
    } else {
      // Fields are filled, send the email
      emailjs.sendForm('service_8inton6', 'template_zenivtb', form.current, 'DMtqVkry-jPHVjNuT')
        .then((result) => {
          console.log(result.text);
          console.log("message sent");
          setIsFormValid(true); // Set the form as valid
          form.current.reset(); // This will clear all form fields
          window.location.reload(); //this will reload the page
        })
        .catch((error) => {
          console.error(error.text);
        });
    }
  };


  const map = ' https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d146452.83705197487!2d-1.8216520685926283!3d55.00245771086616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487d857e0c6f64cd%3A0xbe252b072a76191!2sNewcastle%20upon%20Tyne%2C%20UK!5e0!3m2!1sen!2sng!4v1688832163106!5m2!1sen!2sng" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade '

  return (
    <>
    <StructuredData type="ContactPage" data={contactStructuredData} />
    <Meta
      title="Contact Us | Odins Medical - Healthcare Staffing & Care Support"
      description="Get in touch with Odins Medical for healthcare staffing solutions, nursing recruitment, and care support services in the UK. We are available 24/7 to assist you."
      keywords="contact Odins Medical, healthcare staffing contact, medical recruitment support, nursing agency UK, care services, Odins Medical phone, Odins Medical email" 
    />

    <Header title="Get In Touch Today" image={HeaderImage}>
    </Header>

   <section className='contact padding'>
            <div className='container shadow flexSB'>
              <div className="left roww">
                <iframe src={map}></iframe>
              </div>
              <div className="right roww">
                <h2>We’d love to hear from you!</h2>
                <p>We are open 9am to 5pm Monday to Friday. Our telephone line is open 24/7 </p>

                <div className="items grid">
                  <div className="box">
                    <h4>Address: </h4>
                    <p>Collingwood Buildings, 38 Collingwood Street, Newcastle Upon Tyne, NE1 1JF. </p>
                  </div>
                  <div className="box">
                    <h4>Email:</h4>
                    <p>Admin@odinsmedical.com </p>
                  </div>
                  <div className="box">
                    <h4>Phone:</h4>
                    <p>0191 323 0057 </p>
                  </div>
                </div>

                <div className="contact-form_header mb-1 mt-3">
                  <h4>Contact form:</h4>
                </div>

                <form ref={form} onSubmit={sendEmail}>
                  <div className="flexSBB">
                    <label>Name</label>
                    <input type="text" name="user_name" required/>
                    <label>Email</label>
                    <input type="email" name="user_email" required/>
                    <label>Subject</label>
                    <input type="text" name='Subject'required/>
                  </div>
                  <label>Message</label>
                  <textarea cols="30" rows="5" name="message" required></textarea>
                  <div className="buton">
                    {isFormValid ? (
                      alert('Submitted')
                    ) : (
                      <button className="btttn" type="submit" >
                        Submit
                      </button>
                    )}
                  </div>
                </form>

                <div className="contact__socials">
                <h3>Follow us here:</h3>
                    <a href="https://wa.me/01913230057" target='_blank' rel='noreferrer noopener'> <FaWhatsapp/> </a>
                    <a href="https://www.facebook.com/people/Odins-Medical/61550256904872/" target='_blank' rel='noreferrer noopener'> <FaFacebookF/> </a>
                    <a href="https://twitter.com/OdinsMedical" target='_blank' rel='noreferrer noopener'> <RiTwitterXFill/> </a>
                </div>
              </div>
            </div>

            

        </section>

    
    </>
  )
}

export default Contact