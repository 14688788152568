import MainHeader from '../../components/MainHeader'
import CareSupport from '../../components/CareSupport/CareSupport'
import Programs from '../../components/Programs/Programs'
import About from '../../components/aboutComp/About'
import Contact from '../../components/ContactComp/Contact'
/*import TrustedBy from '../../components/TrustedBy'*/
/*import Hblog from './Hblog'*/
/*import Testimonials from '../../components/Testimonials'*/
/*import FAQs from '../../components/FAQs'*/

import './home.css'
import Meta from '../../components/Meta'
import StructuredData from "../../components/structuredData/StructuredData";





const Home = () => {
  const homeStructuredData = {
    "@type": "WebSite",
    "name": "Odins Medical",
    "url": "https://yourwebsite.com",
    "description": "Odins Medical provides healthcare staffing, care and support services in the UK.",
    "publisher": {
      "@type": "Organization",
      "name": "Odins Medical",
      "logo": "https://yourwebsite.com/images/logo.png"
    }
  };

  return (
    <>
      <StructuredData type="WebSite" data={homeStructuredData} />

      <Meta 
        title="Odins Medical | Healthcare Staffing & Care Support Services" 
        description="Odins Medical provides top-quality healthcare staffing solutions and care support services in the UK. We connect medical professionals with healthcare organizations for temporary and permanent placements."
        keywords="healthcare staffing, nursing jobs, care services, medical recruitment, UK healthcare, home care support, nursing agency, temporary staffing"
      />

      <MainHeader/>
      <CareSupport/>
      <Programs/>
      <About/>
      <Contact/>
      {/*<TrustedBy/>*/}
      {/*<Hblog/>*/}
      {/*<Testimonials/>*/}
      {/*<FAQs/>*/}

    </>

  )
}

export default Home