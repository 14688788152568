import React from 'react'
import Meta from '../../components/Meta'
import Header from "../../components/Header"
import HeaderImage from '../../images/Sign-up.JPG'
import { Link } from 'react-router-dom'
import StructuredData from "../../components/structuredData/StructuredData";
import './accounts.css'




const SignUp = () => {
  const oHubStructuredData = {
    "@type": "WebPage",
    "name": "O-Hub - Register & Login",
    "url": "https://yourwebsite.com/o-hub",
    "description": "Register or login to access Odins Medical's services, job applications, and staffing solutions."
  };

  return (
    <>
      <StructuredData type="WebPage" data={oHubStructuredData} />
      <Meta 
        title="O-HUB | Odins Medical - Healthcare Staffing & Care Support - Register/Login"
        description="Register or login to access Odins Medical's services, job applications, and staffing solutions."
        keywords="register Odins Medical, Odins Medical login, healthcare staffing register, medical recruitment sign in, nursing agency UK, care services, Odins Medical sign in, Odins Medical sign up" 
      />
      <Header title="O-HUB" image={HeaderImage}>
      </Header>

      <section className="o-hub">
        <div className="container o-hub__wrapper">
            <div className="o-hub__content">
              <h2>Welcome to odinsmedical O-hub</h2>
              <p>
                  The agency that takes pride in matching each vacant position with the best possible staff! <span className='TtT'> #Matchingcare. </span>
              </p>
              <div className="bttn__card">
                  <button>
                  <Link to="https://odinsmedical.agencyportal.co.uk/candidate/register" className='lnk'>Register/ Login</Link>
                  </button>
              </div>
            </div>
        </div>
      </section>


    </>
  )
}

export default SignUp